<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span>用量统计</span>
      </template>
      <div :class="$style.manageContent" slot="content">
        <div :class="$style.searchBox">
          <a-range-picker
            :show-time="{ format: 'YYYY-MM-DD' }"
            :ranges="presetTime"
            format="YYYY-MM-DD"
            v-model="dateRange"
            :allowClear="false"
            :placeholder="[
              $t('hat.clockRecord.startDate'),
              $t('hat.clockRecord.endDate'),
            ]"
            style="margin-right: 15px;"
            :disabled-date="disabledDate"
            @ok="handleSearchTime"
            @calendarChange="calendarPriceRangeChange"
          />
          <a-select
            v-model="searchParams.deviceId"
            placeholder="请选择"
            show-search
            option-filter-prop="children"
            :filter-option="true"
            style="width:  220px;"
            @change="handleDeviceSelect"
          >
            <a-select-option
              :value="item.deviceId"
              v-for="item in deviceList"
              :key="item.deviceId"
              >{{
                item.userName
                  ? `${item.deviceName}(${item.userName})`
                  : item.deviceName
              }}</a-select-option
            >
          </a-select>
        </div>
        <div :class="$style.chartBox">
          <div :class="$style.chartItem">
            <div :class="$style.chartTitle">
              <div :class="$style.name">
                <span @click="backLevelOne('IOT')">物联网卡流量</span>
                <span v-if="statisticsData['IOT'].deviceName">{{
                  '>' + statisticsData['IOT'].deviceName
                }}</span>
              </div>
              <div :class="$style.des">
                累计使用：{{ statisticsData['IOT'].chartData.total }}Mb
              </div>
            </div>
            <bar-chart
              :chartData="statisticsData['IOT'].chartData"
              :isVertical="statisticsData['IOT'].chartData.isVertical"
              unit="Mb"
              @click-chart="handleClickChart($event, 'IOT')"
            />
          </div>
          <div :class="$style.chartItem">
            <div :class="$style.chartTitle">
              <div :class="$style.name">
                <span @click="backLevelOne('OSS')">设备文件云存储</span>
                <span v-if="statisticsData['OSS'].deviceName">{{
                  '>' + statisticsData['OSS'].deviceName
                }}</span>
              </div>
              <div :class="$style.des">
                累计使用：{{ statisticsData['OSS'].chartData.total }}Mb(图片：{{
                  statisticsData['OSS'].chartData.tagOneTotal
                }}Mb, 视频：{{ statisticsData['OSS'].chartData.tagTwoTotal }}Mb)
              </div>
            </div>
            <bar-chart
              :chartData="statisticsData['OSS'].chartData"
              :isVertical="statisticsData['OSS'].chartData.isVertical"
              unit="Mb"
              @click-chart="handleClickChart($event, 'OSS')"
            />
          </div>
          <div :class="$style.chartItem">
            <div :class="$style.chartTitle">
              <div :class="$style.name">
                <span @click="backLevelOne('RTC')">通话时长</span>
                <span v-if="statisticsData['RTC'].deviceName">{{
                  '>' + statisticsData['RTC'].deviceName
                }}</span>
              </div>
              <div :class="$style.des">
                累计使用：{{
                  statisticsData['RTC'].chartData.total
                }}分钟(语音：{{
                  statisticsData['RTC'].chartData.tagOneTotal
                }}分钟 视频：{{
                  statisticsData['RTC'].chartData.tagTwoTotal
                }}分钟)
                <a-tooltip>
                  <template slot="title">
                    1、基于设备通话明细中发布的语音、视频统计；<br />
                    2、由于音频和视频是独立发布/订阅，故单一设备的音频+视频时长可能大于等于实际在线时长；<br />
                    3、平台端用户发布的语音、视频不在统计范围内；<br />
                    4、当日的通话明细查询有一天延迟；
                  </template>
                  <a-icon type="question-circle" style="margin-left: 4px;" />
                </a-tooltip>
              </div>
            </div>
            <bar-chart
              :chartData="statisticsData['RTC'].chartData"
              :isVertical="statisticsData['RTC'].chartData.isVertical"
              unit="分钟"
              @click-chart="handleClickChart($event, 'RTC')"
            />
          </div>
          <div :class="$style.chartItem">
            <div :class="$style.chartTitle">
              <div :class="$style.name">
                <span @click="backLevelOne('MESSAGE')">短信用量</span>
                <span v-if="statisticsData['MESSAGE'].deviceName">{{
                  '>' + statisticsData['MESSAGE'].deviceName
                }}</span>
              </div>
              <div :class="$style.des">
                累计使用：{{ statisticsData['MESSAGE'].chartData.total }}条
              </div>
            </div>
            <bar-chart
              :chartData="statisticsData['MESSAGE'].chartData"
              :isVertical="statisticsData['MESSAGE'].chartData.isVertical"
              unit="条"
              @click-chart="handleClickChart($event, 'MESSAGE')"
            />
          </div>
        </div>
        <a-spin :spinning="loading" size="large" />
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import BarChart from './bar-chart.vue';
import moment from 'moment';
import { getUsageStatistics } from '@/services/smart-hat/device-management';
import { getMyDevice } from '@/services/smart-hat/device';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import { Debounce } from 'lodash-decorators';
import { createModal } from '@triascloud/x-components';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    LayoutContent,
    BarChart,
  },
})
export default class HatStatistics extends Vue {
  searchParams = {
    startTime: '',
    endTime: '',
    deviceId: '',
    levelEnum: 'DEVICE',
    type: 'IOT',
  };
  dateRange = [
    // 默认显示一周   今天是最后一天
    this.$moment(new Date().getTime() - 30 * DAY).format('YYYY-MM-DD'),
    this.$moment(new Date().getTime()).format('YYYY-MM-DD'),
  ];
  mounted() {
    this.getDeviceList();
    this.searchParams.startTime = this.$moment(this.dateRange[0]).valueOf();
    this.searchParams.endTime =
      this.$moment(this.dateRange[1]).valueOf() + DAY - 1000;
    this.getAllStatics();
  }
  loading = false;
  async getAllStatics() {
    const levelEnum = this.searchParams.levelEnum;
    const deviceId = this.searchParams.deviceId;
    try {
      this.loading = true;
      await Promise.all([
        this.getIotStatistics(deviceId, levelEnum),
        this.getOssStatistics(deviceId, levelEnum),
        this.getRtcStatistics(deviceId, levelEnum),
        this.getMessageStatistics(deviceId, levelEnum),
      ]);
    } finally {
      this.loading = false;
    }
  }
  deviceList = [
    {
      deviceId: '',
      deviceName: '全部设备',
    },
  ];
  async getDeviceList() {
    const data = {
      current: 1,
      size: 500,
    };
    const { records } = await getMyDevice(data);
    this.deviceList = this.deviceList.concat(records);
  }
  statisticsData = {
    IOT: {
      chartData: {},
      deviceId: '',
      deviceName: '',
    },
    OSS: {
      chartData: {},
      deviceId: '',
      deviceName: '',
    },
    MESSAGE: {
      chartData: {},
      deviceId: '',
      deviceName: '',
    },
    RTC: {
      chartData: {},
      deviceId: '',
      deviceName: '',
    },
  };
  /** 物联网卡流量统计数据 */
  async getIotStatistics(deviceId, levelEnum) {
    await this.getStatistics({
      deviceId,
      type: 'IOT',
      tagList: [''],
      levelEnum,
    });
  }
  /** 设备文件云存储统计数据 */
  async getOssStatistics(deviceId, levelEnum) {
    await this.getStatistics({
      deviceId,
      type: 'OSS',
      tagList: ['图片', '视频'],
      levelEnum,
    });
  }
  /** 通话时长统计数据 */
  async getRtcStatistics(deviceId, levelEnum) {
    await this.getStatistics({
      deviceId,
      type: 'RTC',
      tagList: ['语音', '视频'],
      levelEnum,
    });
  }
  /** 短信用量统计数据 */
  async getMessageStatistics(deviceId, levelEnum) {
    await this.getStatistics({
      deviceId,
      type: 'MESSAGE',
      tagList: [''],
      levelEnum,
    });
  }
  /** 获取统计数据 */
  async getStatistics(obj) {
    const { deviceId, type, tagList = [''], levelEnum = 'DEVICE' } = obj;
    const postData = {
      startTime: this.searchParams.startTime,
      endTime: this.searchParams.endTime,
      levelEnum,
      deviceId,
      type,
    };
    try {
      const { list, total } = await getUsageStatistics(postData);
      this.statisticsData[type].chartData = {
        levelEnum,
        isVertical: deviceId ? false : true,
        deviceId,
        deviceName: '',
        nameList: [],
        dataList: tagList.map(item => ({ tag: item, values: [] })),
        total,
        tagOneTotal: 0,
        tagTwoTotal: 0,
      };
      list.forEach(item => {
        this.statisticsData[type].chartData.nameList.unshift(item.titleName);
        if (item.detail.length) {
          this.statisticsData[type].chartData.tagOneTotal +=
            item.detail[0].value;
          this.statisticsData[type].chartData.tagTwoTotal +=
            item.detail[1].value;
          this.statisticsData[type].chartData.dataList[0].values.unshift({
            deviceId: item?.deviceId,
            value: item?.detail[0].value,
            deviceName: item?.titleName,
            time: item?.time,
          });
          this.statisticsData[type].chartData.dataList[1].values.unshift({
            deviceId: item?.deviceId,
            value: item?.detail[1].value,
            deviceName: item?.titleName,
            time: item?.time,
          });
        } else {
          this.statisticsData[type].chartData.dataList[0].values.unshift({
            deviceId: item?.deviceId,
            value: item?.total,
            deviceName: item?.titleName,
            time: item?.time,
          });
        }
      });
      if (levelEnum === 'TIME') {
        this.statisticsData[type].chartData.nameList.reverse();
        this.statisticsData[type].chartData.dataList[0]?.values.reverse();
        this.statisticsData[type].chartData.dataList[1]?.values.reverse();
      }
      this.statisticsData[type].chartData.tagOneTotal = this.statisticsData[
        type
      ].chartData.tagOneTotal.toFixed(2);
      this.statisticsData[type].chartData.tagTwoTotal = this.statisticsData[
        type
      ].chartData.tagTwoTotal.toFixed(2);
    } catch {
      this.statisticsData[type].chartData = {
        levelEnum: 'DEVICE',
        isVertical: true,
        deviceId: '',
        deviceName: '',
        nameList: [],
        dataList: [],
        total: 0,
        tagOneTotal: 0,
        tagTwoTotal: 0,
      };
    }
  }
  apiEnum = {
    IOT: this.getIotStatistics,
    OSS: this.getOssStatistics,
    RTC: this.getRtcStatistics,
    MESSAGE: this.getMessageStatistics,
  };
  async handleClickChart(params, type) {
    const { deviceId, deviceName, time } = params.data;
    if (this.statisticsData[type].chartData.levelEnum === 'DEVICE') {
      await this.apiEnum[type](deviceId, 'TIME');
      this.statisticsData[type].deviceId = deviceId;
      this.statisticsData[type].deviceName = deviceName;
      this.statisticsData[type].chartData.levelEnum = 'TIME';
    } else if (this.statisticsData[type].chartData.levelEnum === 'TIME') {
      const isMonth = deviceName.length === 2;
      const searchParams = {
        startTime: this.$moment(time || undefined)
          .startOf(isMonth ? 'month' : 'day')
          .valueOf(),
        endTime: this.$moment(time || undefined)
          .endOf(isMonth ? 'month' : 'day')
          .valueOf(),
      };
      switch (type) {
        case 'IOT':
          return;
        case 'OSS':
          this.openManageModal(
            this.statisticsData[type].deviceId,
            'file',
            'CLOUD',
            searchParams,
          );
          break;
        case 'RTC':
          this.openManageModal(
            this.statisticsData[type].deviceId,
            'dosage',
            'rtc',
            searchParams,
          );
          break;
        case 'MESSAGE':
          this.openManageModal(
            this.statisticsData[type].deviceId,
            'dosage',
            'message',
            searchParams,
          );
          break;
        default:
          this.openManageModal(this.statisticsData[type].deviceId);
      }
    }
  }
  backLevelOne(type) {
    this.statisticsData[type].deviceName = '';
    this.apiEnum[type]('', 'DEVICE');
  }
  handleDeviceSelect(v) {
    const idx = this.deviceList.findIndex(item => v === item.deviceId);
    this.searchParams.deviceId = v;
    if (v) {
      this.searchParams.levelEnum = 'TIME';
      Object.keys(this.statisticsData).forEach(item => {
        this.statisticsData[item].deviceName = this.deviceList[idx].deviceName;
        this.statisticsData[item].deviceId = v;
      });
    } else {
      this.searchParams.levelEnum = 'DEVICE';
      Object.keys(this.statisticsData).forEach(item => {
        this.statisticsData[item].deviceName = '';
        this.statisticsData[item].deviceId = '';
      });
    }
    this.getAllStatics();
  }
  @Debounce(300)
  async openManageModal(
    deviceId,
    defaultActiveKey,
    childActiveTab,
    searchParams,
  ) {
    const record = {
      deviceId,
    };
    createModal(
      () => (
        <ManageModel
          record={record}
          defaultActiveKey={defaultActiveKey}
          childActiveTab={childActiveTab}
          params={searchParams}
        />
      ),
      {
        width: 1280,
        title: this.$t('hat.deviceManagement.manage'),
        maskClosable: false,
      },
    );
  }
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 360 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }
  handleSearchTime(time) {
    this.searchParams.startTime = this.$moment(time[0]).valueOf();
    this.searchParams.endTime = this.$moment(time[1]).valueOf();
    this.getAllStatics();
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  font-size: 16px;
  .manageContent {
    height: 100%;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .searchBox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .chartBox {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
      .chartItem {
        position: relative;
        .chartTitle {
          position: absolute;
          top: 6px;
          left: 5px;
          z-index: 99;
          .name {
            cursor: pointer;
            color: var(--font);
          }
          .des {
            margin-top: 8px;
            font-size: 14px;
            color: var(--font-info);
          }
        }
      }
    }
  }
}
</style>
